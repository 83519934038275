import request from './../request.js'

/** -----------限速页面 ----------------*/
// 列表渲染以及查询 /dev-api/iot/rateLimit/pageList
export function rateLimitPageList(query) {
  return request({
    url: '/iot/rateLimit/pageList.do?',
    method: 'get',
    params: query
  })
}

// 新增和修改 /dev-api/iot/rateLimit/save
export function rateLimitSave(data) {
    return request({
      url: '/iot/rateLimit/save.do?',
      method: 'post',
      data: data
    })
  }
//单个删除 /dev-api/iot/rateLimit/delete
export function rateLimitDelete(params) {
  return request({
    url: '/iot/rateLimit/delete.do?',
    method: 'post',
    data:params,
    timeout:20000,
  headers:{'Content-Type': 'multipart/form-data'}
  })
}


/** -----------关联页面 ----------------*/
// 单个新增 /dev-api/iot/rateLimit/detail/pageList
export function detailPageList(query) {
  return request({
    url: '/iot/rateLimit/detail/pageList.do?',
    method: 'get',
    params: query
  })
}

// 单个新增 /dev-api/iot/rateLimit/detail/add
// export function detailAdd(data) {
//   return request({
//     url: '/iot/rateLimit/detail/add.do?',
//     method: 'post',
//     data: data
//   })
// }
//单个删除 /dev-api/iot/rateLimit/detail/delete
export function detailDelete(params) {
  return request({
    url: '/iot/rateLimit/detail/delete.do?',
    method: 'post',
    data:params,
    timeout:20000,
  headers:{'Content-Type': 'multipart/form-data'}
  })
}
// 批量导入下载文件模板/dev-api/iot/rateLimit/importTemplate
export function imeiExport(data){
  return request({
    url: '/iot/rateLimit/importTemplate.do?',
    method: 'post',
    data: data,
    responseType: 'blob'
  })
}

// 批量新增 /dev-api/iot/rateLimit/detail/import
export function detailImport(params) {
  return request ({
      url:'iot/rateLimit/detail/import.do?',
      method:'post',
      data:params,
      timeout:20000,
  headers:{'Content-Type': 'multipart/form-data'}
  })
}

//单个新增 /dev-api/iot/rateLimit/detail/add      
export function detailAdd(params) {
  return request({
    url: '/iot/rateLimit/detail/add.do?',
    method:'post',
      data:params,
      timeout:20000,
  headers:{'Content-Type': 'multipart/form-data'}
  })
}





























// 下载文件模板  /dev-api/iot/device/importTemplate
  export function deviceExport(data){
    return request({
      url: '/iot/device/importTemplate',
      method: 'post',
      data: data,
      responseType: 'blob'
    })
  }

// 批量新增 /dev-api/iot/device/import
export function importBatchStopCardByExcel(params) {
    return request ({
        url:'iot/device/import',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

// 新增分配
export function addDevice(data) {
    return request({
      url: '/iot/device',
      method: 'post',
      data: data
    })
  }
  // 删除/dev-api/iot/device/{ids}
  export function delDevice(ids) {
    return request({
        url: "/iot/device/"+ids,
        method: 'DELETE',
        data:ids,
    })
}


  // 设备操作/dev-api/iot/device/{id}
  export function newDevice(id) {
    return request({
        url: "/iot/device/"+id,
        method: 'get',
        data:id,
    })
}


  