<template>
  <div class="materialsList fadeInRight app-container">
    <div class="title-box clearfix">
      <el-row :gutter="40">
        <el-col :span="19" :xs="24"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="queryParams"
              class="demo-form-inline"
              style="height: auto; line-height: 40px; margin-top:5px"
            >
              <el-form-item label="" :label-width="formLabelWidth" prop="imei">
                <el-input
                  placeholder="请输入设备IMEI"
                  v-model="queryParams.imei"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click="searchData"
                  class="themed-button"
                  >搜索</el-button
                >
              </el-form-item>
            </el-form>
          </div></el-col
        >
        <el-col :span="5" :xs="24"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              style="height: auto; line-height: 40px; margin-top:5px"
            >
              <el-form-item>
                <el-button
                  icon="el-icon-plus"
                  type="primary"
                  class="themed-button"
                  @click="add"
                  >新增</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button
                  icon="el-icon-plus"
                  type="primary"
                  class="themed-button"
                  @click="importAdd"
                  >导入</el-button
                >
              </el-form-item>
            </el-form>
          </div></el-col
        >
      </el-row>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        :max-height="maxTableHeight"
        style="width: 100%"
        :row-style="{ height: '45px' }"
        :cell-style="{ padding: '0px' }"
      >
        <el-table-column prop="imei" label="设备IMEI" min-width="180">
        </el-table-column>
        <el-table-column
          prop="startTime"
          label="当前限速规则"
          min-width="180"
        >
        <template slot-scope="scope">
        <span>{{scope.row.rule.duration}}小时内，使用超过{{scope.row.rule.threshold}}GB，限速{{scope.row.rule.speedLimit}}kbps</span>
        <!-- <span>{{ scope.row.sendTime == ""||scope.row.sendTime == null ? "" :scope.row.sendTime}}</span> -->
        </template>
        </el-table-column>
        <el-table-column
          prop="startTime"
          label="设备加入规则的时间"
          min-width="180"
        >
        </el-table-column>
        <el-table-column prop="afterLimitTraffic" label="开始限速后使用的流量" min-width="180" :formatter="sizeTostr">
        </el-table-column>
        <el-table-column prop="startLimitTime" label="设备开始限速时间" min-width="180">
        </el-table-column>
        <el-table-column prop="endCountTime" label="设备结束限速的时间" min-width="180">
        </el-table-column>
        <el-table-column
          prop="totalTraffic"
          label="规则启用后的累计总流量"
          min-width="180"
         :formatter="sizeTostr"
        >
        </el-table-column>
        <el-table-column prop="state" label="状态" min-width="180">
          <template slot-scope="scope">
            <span>{{
              scope.row.state === "1"
                ? "未限速"
                : scope.row.state === "2"
                ? "已下发"
                : scope.row.state === "3"
                ? "下发成功"
                : scope.row.state === "4"
                ? "下发失败"
                : "超时解除"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="themed-button"
              @click="delect(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>

      <el-dialog
        title="新增分配"
        :visible.sync="dialogFormVisible"
        width="600px"
      >
        <el-form
          ref="addForm"
          :model="addForm"
          label-width="120px"
          :rules="rules"
        >
          <el-form-item label="设备IMEI" prop="imeiArray">
            <el-input v-model="addForm.imeiArray" type="textarea" placeholder="请输入设备IMEI" style="width: 80%" :autosize="{ minRows: 3, maxRows: 4}"></el-input>
          </el-form-item>
           <el-form-item prop="isUpdate" label="IMEI重复策略" label-width="140px" style="margin-top:60px">
            <el-radio-group v-model="addForm.isUpdate">
                <el-radio :label="1">覆盖</el-radio>
                <el-radio :label="2">跳过</el-radio>
              </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false" type="info" class="themed-button">取 消</el-button>
          <el-button type="primary" class="themed-button" @click="sureAddOnce('addForm')"
            >确 定</el-button
          >
        </div>
      </el-dialog>
      <el-dialog title="批量导入" :visible.sync="dialogVisible" width="600px">
        <el-form ref="fileForm" :model="fileForm" label-width="120px">
          <el-form-item>
            <div style="height: 50px; line-height: 50px">
              下载模板:
              <a
                href="javascript:void(0);"
                @click="exportTemplateExcel('报表数据.xls')"
                style="color: blue"
                >报表数据</a
              >
            </div>
            <div style="height: 50px; line-height: 50px">
              <input class="file-input" type="file" ref="file" />
              <div class="text-color" style="font-size: 12px">
                仅支持.xls/.xlsx格式
              </div>
            </div>
          </el-form-item>
           <el-form-item prop="isUpdates" label="IMEI重复策略" label-width="220px" style="margin-top:80px">
            <el-radio-group v-model="fileForm.isUpdate">
                <el-radio :label="1">覆盖</el-radio>
                <el-radio :label="2">跳过</el-radio>
              </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" type="info" class="themed-button">取 消</el-button>
          <el-button type="primary" @click="importAureAdd('fileForm')" class="themed-button"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import resize from '@/mixins/resize.js'
import { agentGetAllAgent } from "@/api/system/user";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Utils from "@/utils/utils.js";
import {
  detailPageList,
  detailAdd,
  detailDelete,
  imeiExport,
  detailImport,
} from "@/api/speedlimitBox/speedlimitBox.js";
export default {
  name: "materialsList",
   mixins:[resize],
  components: { Treeselect },
  //mixins: [resize],
  data() {
    return {
      formInline: {
        spec: "",
        material: "",
      },
      addForm: {
        imei: "",
        isUpdate:1
      },
      fileForm: {
      isUpdate:1
      },
      tableData: [],
      materialList: [],
      specList: [],
      agentOptions: [],
      dialogFormVisible: false,
      dialogVisible: false,
      queryParams: {
        imei: "",
      },
      rules: {
        imeiArray: [
          {
            required: true,
            message: "设备IMEI不能为空",
            trigger: "blur",
          },
        ],
      },
      page: 1,
      pageSize: 15,
      total: 0,
      formLabelWidth: "10px",
      queryCount: "",
    };
  },
  created() {
    this.queryCount = JSON.parse(this.$route.query.data);
    console.log(this.queryCount, 99999);
    this.getDeviceList();
  },
  methods: {
    sizeTostr(row, column, cellValue) {
      if (column.label=="开始限速后使用的流量") {
      var size = parseInt(row.afterLimitTraffic)  
      }
      if (column.label=="规则启用后的累计总流量") {
      var size = parseInt(row.totalTraffic)  
      }
    // var size = parseInt(row.totalTraffic)
    // var size = parseInt(row.afterLimitTraffic)
    var data = "";
    if (size < 0.1 * 1024) { //如果小于0.1KB转化成B  
        data = size.toFixed(2) + "B";
    } else if (size < 0.1 * 1024 * 1024) {//如果小于0.1MB转化成KB  
        data = (size / 1024).toFixed(2) + "KB";
    } else if (size < 0.1 * 1024 * 1024 * 1024) { //如果小于0.1GB转化成MB  
        data = (size / (1024 * 1024)).toFixed(2) + "MB";
    } else { //其他转化成GB  
        data = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
    }
    var sizestr = data + "";
    var len = sizestr.indexOf("\.");
    var dec = sizestr.substr(len + 1, 2);
    if (dec == "00") {//当小数点后为00时 去掉小数部分  
        return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
    }
    return sizestr;
} ,
    /** 列表渲染 */
    getDeviceList() {
      this.loading = true;
      let params = {
        pageNum: this.page,
        pageSize: this.pageSize,
        ruleId:this.queryCount.id
      };
      Object.assign(this.queryParams, params);
      detailPageList(this.queryParams).then((res) => {
        this.tableData = res.rows;
        this.tableData.forEach((ele) => {
          let hoursCount = new Date(ele.startTime).getTime();
          let hoursLittle = ele.rule.duration * 60 * 60 * 1000;
          ele["time"] = hoursCount + hoursLittle;
          ele.endCountTime = this.formatDate(ele.time);
        });
        this.total = res.total;
        this.loading = false;
      });
    },
    formatDate(str) {
      //Date.now()        //时间戳
      let date = new Date(str); //获取系统时间
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      let day = date.getDate();
      day = day < 10 ? "0" + day : day;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      //let w = date.getDay();        //获取星期几
      return year + "-" + month + "-" + day + " " + h + ":" + m + ":" + s;
    },
    /** 重置表单 */
    reset() {
      this.addForm = {
        ruleId :"",
       imeiArray:"",
       isUpdate:1
      };
      //this.resetForm("addForm");
    },
    /** 新增按钮弹出dialog */
    add() {
      this.reset();
      this.dialogFormVisible = true;
    },
    /** 单个新增提交 */
   sureAddOnce() {
      let flag = true;
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          let queryFox = new FormData();
          queryFox.append("ruleId", this.queryCount.id);
          queryFox.append("isUpdate", this.addForm.isUpdate);
           let real_src_list = this.addForm.imeiArray.split("\n");
            let list = Array.from(real_src_list);
             if (list.length > 10) {
                flag = false;
                this.$alert("输入上限为10组", "提示", {
                  confirmButtonText: "确定",
                });
              } else {
                list.forEach((item, i) => {
                  const numberConst=!isNaN(parseFloat(item))
                  if (!numberConst) {
                    flag = false;
                    this.$alert("只能为数字", "提示", {
                      confirmButtonText: "确定",
                    });
                  }
                  if (item.length > 17||item.length <15) {
                    flag = false;
                    this.$alert("每组为15位到17位长度的数字", "提示", {
                      confirmButtonText: "确定",
                    });
                  } else {
                   queryFox.append("imeiArray", item);
                  }
                });
              }
               if (flag) {
              detailAdd(queryFox).then((res) => {
                if (res.code == "0") {
                  const alert_content = res.msg
                  this.$alert(alert_content, "提示", {
                    confirmButtonText: "确定",
                    dangerouslyUseHTMLString: true
                  }).then(() => {
                    this.dialogFormVisible = false;
                    this.getDeviceList();
                  });
                } else {
                  this.$message({
                    showClose: true,
                    message: res.msg,
                    type: "error",
                  });
                }
              });
            }
        }
      });
    },
    /** 单个删除 */
    delect(id) {
       let paramsCount = new FormData()
      paramsCount.append('detailId',id)
      this.$confirm("是否确认删除该项数据?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        detailDelete(paramsCount).then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getDeviceList();
        });
      });
    },
    /** 导入唤醒dailog按钮 */
    importAdd() {
      this.dialogVisible = true;
    },
    /** 导出模板 */
    exportTemplateExcel(name) {
      imeiExport({ name: name }).then((res) => {
        const filename = name;
        Utils.createDownload(res, filename);
      });
    },
    /** 批量新增确定提交按钮 */
    importAureAdd() {
      this.$confirm("确定导入吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let file = this.$refs["file"].files[0];
        if (file) {
          let fileName = file.name;
          let index = fileName.lastIndexOf(".");
          if (
            index < 0 ||
            !(
              fileName.substring(index + 1, fileName.length) == "xls" ||
              fileName.substring(index + 1, fileName.length) == "xlsx"
            )
          ) {
            this.$alert("文件格式错误", "提示", {
              confirmButtonText: "确定",
            });
            return;
          } else if (!!Utils.checkFile(file)) {
            if (Utils.checkFile(file) == 1) {
              messageBox(this, "上传文件不能超过20M");
              return;
            } else if (Utils.checkFile(file) == 2) {
              messageBox(this, "上传文件名称长度不能超过36个字符");
              return;
            }
          } else {
            let param = new FormData();
            param.append("file", file);
            param.append("ruleId", this.queryCount.id);
            param.append("isUpdate", this.fileForm.isUpdate);
            detailImport(param).then((res) => {
              if (res.code == "0") {
                this.$alert("已上传，请到上传记录中查看", "提示", {
                  confirmButtonText: "确定",
                }).then(() => {
                  this.dialogFormVisible = false;
                  this.getDeviceList();
                  this.$router.push("/equipmentManagement/historyList");
                });
              } else {
                this.$message({
                  showClose: true,
                  message: res.system_result_message_key,
                  type: "error",
                });
              }
            });
          }
        } else {
          this.$alert("请选择文件", "提示", {
            confirmButtonText: "确定",
          });
          return;
        }
      });
    },
    /** 搜索 */
    searchData() {
      this.page = 1;
      this.getDeviceList();
    },
    /** 分页 */
    onPageChange(page) {
      this.page = page;
      this.getDeviceList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getDeviceList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-treeselect {
  width: 250px;
}
::v-deep .el-dialog__header {
  border-bottom: 2px solid rgb(236, 231, 231);
}
.choose-title {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid rgb(236, 231, 231);
  color: rgb(255, 151, 2);
}
.choose-option {
  height: 100px;
  line-height: 100px;
}
.choose-optionOne {
  padding-top: 35px;
  height: 100px;
}

.choose-imei {
  display: flex;
  margin-top: 30px;
  .choose-imei-title {
    margin-right: 100px;
  }
}
.choose-input {
  width: 600px;
}
.m-title {
  color: #676a6c;
  font-weight: 700;
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  padding-left: 50px;
  border-bottom: 1px solid #d1d4d6;
}
.materialsFee .el-form-item__content {
  width: 206px;
  display: inline-block;
}
.materialsList .el-form-item__label {
  vertical-align: top;
}
</style>